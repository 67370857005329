import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  contentContainer: {
    paddingInline: 8,
    height: 100,
    display: 'flex',
    gap: 20,
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    '&:after': {
      content: `""`,
      display: 'block',
      position: 'absolute',
      bottom: -1,
      left: 0,
      right: 0,
      height: 1,
      backgroundColor: '#EEEEEE',
    },
    [theme.fn.largerThan('md')]: {
      padding: 10,
      flexDirection: 'row',
      alignItems: 'center',
    },
  },

  firstRole: {
    marginTop: 25,
  },

  leftContainer: {
    flexWrap: 'nowrap',
    [theme.fn.largerThan('md')]: {
      display: 'flex',
      flexGrow: 1,
    },
  },

  rightContainer: {
    flexWrap: 'nowrap',
    gap: 0,
    display: 'none',
    [theme.fn.largerThan('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },

  group: {
    width: '100%',
    alignItems: 'flex-start',
    flexDirection: 'column',
    [theme.fn.largerThan('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  fav: {
    display: 'inline',
    marginBottom: 'auto',
  },

  fullWidth: {
    width: '100%',
  },

  interviews: {
    ':before': {
      // content: '"INTERVIEWS"',
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      fontFamily: 'Helvetica',
      color: '#B3B3B3',
      position: 'absolute',
      top: -42,
      left: 0,
      right: 0,
    },
  },

  newTalent: {
    ':before': {
      // content: '"NEW TALENT"',
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      fontFamily: 'Helvetica',
      color: '#B3B3B3',
      position: 'absolute',
      top: -42,
      left: 0,
      right: 0,
    },
  },

  createdAt: {
    ':before': {
      // content: '"CREATED ON"',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'Helvetica',
      color: '#B3B3B3',
      position: 'absolute',
      top: -42,
      left: 0,
      right: 0,
    },
  },

  lastReviewed: {
    ':before': {
      content: '"LAST REVIEWED"',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'Helvetica',
      color: '#B3B3B3',
      position: 'absolute',
      top: -42,
      left: 0,
      right: 0,
    },
  },
  userIcon: {
    height: '19px'
  },
  calendarIcon: {
    height: '20px'
  },
  menuIcon: {
    height: '16px',
    width: '16px'
  },
  roleMembersIcon: {
    width: '16px',
    height: '15px'
  }
}));
