import React from 'react';
import TitleWithClose from '../components/TitleWithClose';
import { ContextModalProps } from '@mantine/modals';
import { Text, Group } from '@mantine/core';
import RolebotButton from 'components/public/Buttons/RolebotButton';
import { AutopilotSetUpProps } from 'types/modals';
import { useUpdateRoleAutopilotMutation } from 'app/services/rolebot';

const AutopilotSetUp = ({ id, context, innerProps }: ContextModalProps<AutopilotSetUpProps>) => {

  const [isAutopilotEnabled, setIsAutopilotEnabled] = React.useState<boolean>(innerProps.is_autopilot);
  const [updateAutopilot] = useUpdateRoleAutopilotMutation();

  const handlePrimaryButtonClick = async () => {
    try {
      const newAutopilotState = !isAutopilotEnabled;
      
      await updateAutopilot({
        roleId: innerProps.role.id,
        payload: {
          is_autopilot: newAutopilotState
        }
      }).unwrap();

      setIsAutopilotEnabled(newAutopilotState);
      
      if (innerProps.onComplete) {
        innerProps.onComplete(newAutopilotState);
      }
      
      context.closeModal(id);
    } catch (error) {
      setIsAutopilotEnabled(isAutopilotEnabled);
    }
  };

  return (
    <>
      <TitleWithClose id={id} title={'Autopilot'} />
      <Text mb={20} color={'#242424'}>
        {isAutopilotEnabled 
          ? 'Disabling autopilot will revert candidate review to a manual process, ensuring only accepted candidates are engaged for interviews.'
          : "You no longer need to review talent daily — Rolebot will now automatically designate all sourced candidates as ‘accepted.’ For roles that are not sourcing only, Rolebot will also engage them directly to schedule interviews."}
      </Text>
      <Group position={'right'} mt={40}>
        <RolebotButton onClick={handlePrimaryButtonClick}>
          {isAutopilotEnabled ? 'Turn Off' : 'Turn On'}
        </RolebotButton>
      </Group>
    </>
  );
};

export default AutopilotSetUp;