import RolebotBadge from '../RolebotBadge';
import { Group } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { IRole } from 'types';
import { useGetCompanyQuery } from 'app/services/rolebot';
import { nanoid } from 'nanoid';
import { useModals } from '@mantine/modals';
interface RoleBadgesProps extends IRole {
  id: number;
  minimal?: boolean;
  className?: string;
  talent_to_review?: number;
  is_autopilot: boolean;
  show_autopilot_badge?: boolean;
  role: IRole;
}

const RoleBadges = ({
  on_hold,
  show_info,
  needs_info_update,
  completed,
  role_requests,
  minimal = false,
  className,
  id,
  talent_to_review = 0,
  is_autopilot,
  show_autopilot_badge,
  role
}: RoleBadgesProps) => {
  const { data } = useGetCompanyQuery();
  const isWaiting = (type: 'open' | 'close') => {
    return role_requests.length > 0 && role_requests.find((x) => x.type === type);
  };
  const modals = useModals();

 
  const [isAutopilotoEnabled, setIsAutopilotoEnabled] = useState(is_autopilot);

  useEffect(() => {
    setIsAutopilotoEnabled(is_autopilot);
  }, [is_autopilot]);


  const handleAutopilotClick = () => {  
    modals.openContextModal('autopilotSetUp', {
      innerProps: {
        role: role,
        onComplete: (newAutopilotState: boolean) => {
          setIsAutopilotoEnabled(newAutopilotState);
        },
        is_autopilot: isAutopilotoEnabled
      }
    });
  }

  
  let badges = [];

  // if we don't have stripe connected already and is not any kind of the above
  if (data?.force_collect_card && data?.stripe_id === null) {
    badges.push(
      <RolebotBadge key={`${nanoid(6)}-card-required`} variant={'filled'} color={'#46209E'} backgroundColor={'#EEEEEE'}>
        Card Required
      </RolebotBadge>
    );
  }

  if (minimal && talent_to_review > 0)
    badges.push(
      <RolebotBadge
        key={`${nanoid(6)}-talent-to-review`}
        variant={'filled'}
        color={'#46209E'}
        backgroundColor={'#EEEEEE'}
      >
        {talent_to_review} new
      </RolebotBadge>
    );

  if (on_hold)
    badges.push(
      <RolebotBadge key={`${nanoid(6)}-on-hold`} variant={'filled'} color={'#46209E'} backgroundColor={'#EEEEEE'}>
        On Hold
      </RolebotBadge>
    );

  if (show_info || needs_info_update)
    badges.push(
      <RolebotBadge
        key={`${nanoid(6)}-waiting-for-setup`}
        variant={'filled'}
        color={'#46209E'}
        backgroundColor={'#EEEEEE'}
        // onClick={handleBadgeClick}
        style={{ cursor: 'pointer' }}
      >
        {needs_info_update ? 'Update Required' : 'Waiting for Setup'}
      </RolebotBadge>
    );

  if (!completed && isWaiting('close'))
    badges.push(
      <RolebotBadge
        key={`${nanoid(6)}-closure-pending`}
        variant={'filled'}
        color={'#46209E'}
        backgroundColor={'#EEEEEE'}
      >
        Closure Pending
      </RolebotBadge>
    );

  if (role_requests?.find((x) => x.type === 'open'))
    badges.push(
      <RolebotBadge
        key={`${nanoid(6)}-closure-pending`}
        variant={'filled'}
        color={'#46209E'}
        backgroundColor={'#EEEEEE'}
      >
        Reactivation Pending
      </RolebotBadge>
    );

  if (minimal && badges.length > 1) {
    badges = [
      <div className={className} key={`${nanoid(6)}-more-badges`}>
        <RolebotBadge color={'#FFF'} backgroundColor={'#D7378C'} variant={'filled'}>
          {badges.length} new
        </RolebotBadge>
      </div>,
    ];
  }

  if (!(show_info || needs_info_update) && show_autopilot_badge) {
  badges.push(
    <RolebotBadge 
      key={`${nanoid(6)}-autopilot`} 
      variant={'filled'} 
      color={'#46209E'} 
      backgroundColor={'#EEEEEE'}
      onClick={handleAutopilotClick}
    >
      {isAutopilotoEnabled ? 'Autopilot On' : 'Autopilot Off'}
    </RolebotBadge>
  );
}
  
  return (
    <Group spacing={'xs'} noWrap className={className}>
      {badges}
    </Group>
  );
};
export default RoleBadges;
