import { useLocation } from 'react-router-dom';
import { useGetCompanyQuery } from 'app/services/rolebot';
import { isFreemiumPlan } from 'utils';
import { useMemo } from 'react';

interface FreemiumCheck {
  isCustomerLoading: boolean;
  isFreemium: boolean | null;
}

export const useFreemiumCheck = (): FreemiumCheck => {
  const currentPath = useLocation().pathname;
  const isPublicRoute = currentPath === '/';

  const { 
    data: company, 
    isLoading: isCompanyLoading, 
    error: companyError
  } = useGetCompanyQuery(undefined, {
    skip: isPublicRoute
  });

  const isFreemium = useMemo<boolean | null>(() => {
    if (companyError) {
      return null;
    }
    if (isCompanyLoading || !company) {
      return null;
    }
    return isFreemiumPlan(company);
  }, [company, isCompanyLoading, companyError]);

  return {
    isCustomerLoading: isCompanyLoading && !companyError,
    isFreemium
  };
}; 