import TitleWithClose from '../components/TitleWithClose';
import { ContextModalProps } from '@mantine/modals';
import { RoleLimitReachedProps } from '../../../types/modals';
import { Button, Group, Text, createStyles } from '@mantine/core';
import { toastSuccess } from 'utils/toastify-messages';
import { useRequestUpgradeInfoMutation, useGetCompanyQuery } from 'app/services/rolebot';
import { isFreemiumPlan } from 'utils';
import { useMemo } from 'react';

const useStyles = createStyles((theme) => ({
  okayButton: {
    backgroundColor: '#FFF',
    color: '#838485',
    borderRadius: '6px',
    border: '1px solid #838485',
    paddingRight: '20px',
    paddingLeft: '20px',
    fontSize: '16px',
    '&:hover': {
      backgroundColor: '#EEEEEE',
      color: '#4F4F4F',
      border: '1px solid #4F4F4F',
    },
  },
  submitButton: {
    color: '#7039ED',
    background: 'none',
    fontSize: '16px',
    '&:hover': {
      textDecorationLine: 'underline',
      background: 'none'
    },
  }
}));

const RoleLimitReached = ({ id, context, innerProps }: ContextModalProps<RoleLimitReachedProps>) => {
  const { data: company } = useGetCompanyQuery();
  const [requestUpgradeInfo, { isLoading, isSuccess, isError, reset: resetMutation }] = useRequestUpgradeInfoMutation();
  const { classes } = useStyles();

  const isFreemium = useMemo(() => {
    return isFreemiumPlan(company);
  }, [company]);

  const handleCloseModal = () => {
    context.closeModal(id);
  }

  const handleSelectPlan = () => {
    window.open(process.env.REACT_APP_SHOPIFY_SELECT_PLAN_URL + "/pages/pricing", '_blank');
  }
  const handleRequestInfo = async () => {
    try {
      await requestUpgradeInfo({
        customer_id: company?.id,
      }).unwrap();
  
      context.closeModal(id);
  
      toastSuccess(
        <div className="toastContainer">
          <div>Great!</div>
          <div>A Customer Success Representative will contact you for next steps.</div>
        </div>
      );

    } catch(e: any) {
      console.log('roleLimit error', e);
    }
  }

  return (
    <>
      <TitleWithClose id={id} title={'Role limit reached'}/>
      <>
        <Text mb={20} color={'#242424'}>
            Congratulations on your company's growth! Your company has reached its role limit, but don't worry, we have options to help you continue finding the best candidates.
        </Text>
        <Text mb={20} color={'#242424'}>
            You can either close existing roles to open up new ones, or easily upgrade your plan for more opportunities.
        </Text>
        <Text mb={20} color={'#242424'}>
            Take your company's hiring to the next level!
        </Text>
      </>
      <Group position={'right'} mt={40}>
        {!isFreemium && (
          <Button disabled={isLoading} className={classes.submitButton} onClick={handleRequestInfo}>
            Request upgrading info
          </Button>
        )}
        {isFreemium && (
          <Button disabled={isLoading} className={classes.submitButton} onClick={handleSelectPlan}>
            Select your plan now!
          </Button>
        )}

        <Button disabled={isLoading} className={classes.okayButton} onClick={handleCloseModal}>
          Okay
        </Button>
      </Group>
    </>
  );
};

export default RoleLimitReached;