import React from 'react';
import { Group, Text, Tooltip } from '@mantine/core';

interface RoleInfoIconProps {
  hasData: boolean;
  amount?: number;
  name: string;
  type?: 'talent-count' | 'interviews';
  className?: string;
  children: React.ReactNode;
}

const RoleInfoIcon = ({ hasData, amount, children, name, type, className }: RoleInfoIconProps) => {
  return (
    <Tooltip
    label={
      <div>
        <div style={{ textAlign: 'center' , fontWeight: 'bold'}}>{name}: {amount}</div>
      </div>
    }
      styles={{
        body: {
          fontSize: 12,
          fontFamily: 'Helvetica',
        },
      }}
      mr={20}
      placement={'center'}
    >
      <Group
        className={className}
        spacing={4}
        sx={{
          minWidth: 80,
          color: hasData ? '#838485' : '#DFE1E1',
          flexWrap: 'nowrap',
        }}
        direction={'row'}
        noWrap
        align={'center'}
        position={'center'}
      >
        <Group>{children}</Group>
      </Group>
    </Tooltip>
  );
};

export default RoleInfoIcon;
