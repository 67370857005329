import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  isAuthorized: boolean;
  redirectPath?: string;
  children: ReactNode;
}

const ProtectedRoute = ({
  isAuthorized,
  redirectPath = '/',
  children
}:  ProtectedRouteProps) => {

  if (!isAuthorized) {
    return <Navigate to={redirectPath} />;
  }

  return <>{children}</>;
};

export default ProtectedRoute; 