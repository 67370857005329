import React, { useEffect, useState } from 'react';
import { Divider, Group, MediaQuery, Menu, Stack, Text, Tooltip, UnstyledButton, Button } from '@mantine/core';
import { Dots } from 'tabler-icons-react';
import { useStyles } from './roleStyles';
import RoleLocation from 'components/RoleRow/RoleLocation';
import RoleDate from 'components/RoleRow/RoleDate';
import RoleName from 'components/RoleRow/RoleName';
import RoleInfoIcon from 'components/RoleRow/RoleInfoIcon';
import RoleBadges from 'components/RoleRow/RoleBadges';
import { IRole } from 'types';
import { useModals } from '@mantine/modals';
import moment from 'moment';
import { useGetCompanyQuery } from 'app/services/rolebot';
import { useDisclosure } from '@mantine/hooks';
import IntegrationIcon from 'components/IntegrationIcon/IntegrationIcon';
import useAppSelector from '../../hooks/useAppSelector';
import useOpenModal from 'hooks/useOpenModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RolebotClient } from 'api/client';
import { sendGoogleEvent } from 'utils/analytics';
import {
  faUser,
  faCalendarCheck,
  faCalendarXmark,
  faUserPlus,
  faArrowDownToLine,
  faInfoCircle,
  faCirclePause,
  faCirclePlay,
  faXmark,
  faLink
} from '@fortawesome/pro-light-svg-icons';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { selectATSRole, selectATSRoleName } from 'features/app/appSlice';
import { isFreemiumPlan } from 'utils';

interface RoleProps {
  role: IRole;
  index: number;
  handlePatchCompleted: (action: string, roleId: number | string) => void;
  handleSetupComplete: (isCompleted: boolean, roleId: number) => void;
  handleNavigateToRole: (roleId: number | string, roleName: string) => void;
}

const RoleRow = ({
  role,
  index,
  handlePatchCompleted,
  handleSetupComplete,
  handleNavigateToRole
}: RoleProps) => {
  const sortType = useAppSelector((state) => state.app.sortType);
  const { data } = useGetCompanyQuery();
  const { name, show_info } = role;
  const { classes, cx } = useStyles();
  const modals = useModals();
  const { resumeRole, pauseRole, roleSetup, closeRole, linkToATSModal, roleLimitReachedModal } = useOpenModal();
  const [opened, handlers] = useDisclosure(false);
  const dispatch = useAppDispatch();

  const showAdditionalEmails = role.sourcing_only && (data?.enable_contact_out || data?.enable_rocket_reach);

  const handleResumeRole = () => {
    resumeRole({
      roleName: role.name,
      roleId: role.id,
      withCloseButton: true,
      withTitle: true,
      onPatchCompleted: handlePatchCompleted
    });
  };

  const handleRoleInfo = () => {
    roleSetup({
      roleId: role.id,
      roleName: role.name,
      initialStep: 1,
      showInfo: role?.show_info,
      onSetupComplete: handleSetupComplete,
    });
  };

  const handleRoleUsers = () => {
    roleSetup({
      roleId: role.id,
      roleName: role.name,
      initialStep: role.sourcing_only ? 4 : 6,
      showInfo: role?.show_info,
    });
  };

  const handlePauseRole = () => {
    pauseRole({
      roleName: role!.name,
      roleId: role!.id,
      onPatchCompleted: handlePatchCompleted
    });
  };

  const handleCloseRole = () => {
    closeRole({
      roleName: role.name,
      roleId: role.id,
      companyId: role.customer_id,
      withCloseButton: true,
      withTitle: true,
      reload: true,
      onPatchCompleted: handlePatchCompleted
    });
  };

  const handleOpenRole = () => {
    if (data?.active_role_limit && data?.active_role_count >= data?.active_role_limit) {
      roleLimitReachedModal({ company: data });
    } else {
      sendGoogleEvent('User', 'Role settings open role clicked');

      modals.openContextModal('openRole', {
        innerProps: {
          roleName: role.name,
          roleId: role.id,
          customerId: role.customer_id,
          withCloseButton: true,
          withTitle: true,
          reload: true,
          onPatchCompleted: handlePatchCompleted
          },
        });
    }
  };

  const handleLinkToATS = (roleId : number, roleName: string) => {

    dispatch(
      selectATSRole(roleId)
    );

    dispatch(
      selectATSRoleName(roleName)
    );
    
    linkToATSModal({
      greenhouse: data?.greenhouse,
      lever: data?.lever,
      sap: data?.sap,
      icims: data?.icims,
      roleId: roleId,
      roleName: roleName
    })
  }

  const getTalentList = async () => {
    try {

      let areMoreTalent = true;
      let page = 1;
      let totalCount = 0;
      let talentList: any[] = [];

      //Here we iterate over the talent list until we have all of the talent.
      while (areMoreTalent) {

        const request = {
          'talent-filter-type': 'all',
          'page': page,
          'per_page': 30,
        };

        const { data } = await RolebotClient.get('/roles/' + role.id + '/talent', {	params: request	})

        const talent = data.data;
        const total = data.total;

        if (page === 1) {
          totalCount = total;
        }

        talentList = talentList.concat(talent);

        if (talentList.length >= totalCount) {
          areMoreTalent = false;
        }

        page++;
      }

      return talentList;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const handleExportTalentClicked = async () => {
    /*sendUserGoogleEvent("role settings export clicked");*/

    //At this point, we don't have any talent list because we changed that to be paginated.
    //So we need to get the talent list first, then export it.
    const talent = await getTalentList();

    const rows = [['name', 'location', 'current job title', 'link', 'diverse', 'accepted', 'skipped reason', 'outcome', 'interview date', 'talent emails', 'talent phones']];

    const payload = rows.concat(
      talent.map((x) => {
        let acceptedStatus;
        switch (true) {
          case x.pivot.accepted === true:
            acceptedStatus = 'accepted';
            break;
          case x.pivot.accepted === false:
            acceptedStatus = 'declined';
            break;
          default:
            acceptedStatus = x.pivot.skipped_reason !== null ? 'skipped' : 'waiting';
        }

        let skippedReason;
        switch (true) {
          case x.pivot.skipped_reason !== null:
            skippedReason = x.pivot.skipped_reason;
            break;
          default:
            skippedReason = null;
        }

        let outcome;
        switch (true) {
          case x.pivot.outcome_id === 1:
            outcome = 'Happy in role';
            break;
          case x.pivot.outcome_id === 2:
            outcome = 'Commute Distance';
            break;
          case x.pivot.outcome_id === 3:
            outcome = 'Comp Differential';
            break;
          case x.pivot.outcome_id === 4:
            outcome = 'No Response';
            break;
          case x.pivot.outcome_id === 5:
            outcome = 'Not Interested';
            break;
          case x.pivot.outcome_id === 6:
            outcome = 'Interview';
            break;
          case x.pivot.outcome_id === 7:
            outcome = 'Requires visa Sponsorship';
            break;
          case x.pivot.outcome_id === 8:
            outcome = 'Seeking remote opportunities';
            break;
          case x.pivot.outcome_id === 9:
            outcome = 'Seeking hybrid opportunities';
            break;
          case x.pivot.outcome_id === 10:
            outcome = 'Not interested in relocating';
            break;
          default:
            outcome = null;
        }

        let interviewDate;
        switch (true) {
          case x.interviews.length > 0:
            interviewDate = moment.utc(x.interviews[x.interviews.length - 1].interview_at).format();
            break;
          default:
            interviewDate = null;
        }

        let isDiverse;
        switch (true) {
          case x.is_diverse === true:
            isDiverse = 'yes';
            break;
          case x.is_diverse === false:
            isDiverse = 'no';
            break;
          default:
            isDiverse = 'unknown';
        }

        let talentCurrentJobTitle = x?.job_titles[0] ? x.job_titles[0].name : "";
        let talentLocation = x.talent_info?.location ? `"${x.talent_info.location}"` : "";

        if ((outcome !== 'Interview') || (interviewDate !== null)) {
          const contactOut = x.contact_out_email;
          const rocketReach = x.rocket_reach_email;
          const rolebotEmail = x.email;
          const mobileNumber = x.mobile_number;
          const altNumber = x.alt_number;
          
          let talentEmails: string[] = [];
          let talentNumbers = [];
          
          if (data?.enable_contact_out && contactOut?.length && showAdditionalEmails) talentEmails.push(...contactOut);
          if (data?.enable_rocket_reach && rocketReach?.length && showAdditionalEmails) talentEmails.push(...rocketReach);
          if (rolebotEmail) talentEmails.push(rolebotEmail);
          const uniqueEmails = [...new Set(talentEmails)];

          if (mobileNumber) talentNumbers.push(mobileNumber);
          if (altNumber) talentNumbers.push(altNumber);
          const uniqueNumbers = [...new Set(talentNumbers)];

          return [
            x.name,
            talentLocation,
            talentCurrentJobTitle,
            x.link,
            isDiverse,
            acceptedStatus,
            skippedReason,
            outcome,
            interviewDate,
            (uniqueEmails.length > 0 && acceptedStatus !== 'waiting') ? [ '\"' + uniqueEmails.join(',') + '\"'] : [],
            (uniqueNumbers.length > 0 && acceptedStatus !== 'waiting') ? [ '\"' + uniqueNumbers.join(',') + '\"'] : [],
          ];
        }

        return [
          x.name,
          talentLocation,
          talentCurrentJobTitle,
          x.link,
          isDiverse,
          acceptedStatus,
          skippedReason,
          outcome,
          interviewDate,
          "",
          ""
        ];
      })
    );

    let csvContent = payload.map((e) => e.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    /*if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, `${role.name.split(' ').join('_')}_talent_export.csv`);
    } else {*/
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `${role.name.split(' ').join('_')}_talent_export.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    //}
  };

  const [isAutopilotoEnabled, setIsAutopilotoEnabled] = useState(role?.is_autopilot);

  useEffect(() => {
    console.log('Setting autopilot state from role:', role?.is_autopilot);
    setIsAutopilotoEnabled(role?.is_autopilot);
  }, [role?.is_autopilot]);

  const handleAutopilotClick = () => {  
    modals.openContextModal('autopilotSetUp', {
      innerProps: {
        role: role,
        onComplete: (newAutopilotState: boolean) => {
          setIsAutopilotoEnabled(newAutopilotState);
        },
        is_autopilot: isAutopilotoEnabled
      }
    });
  }



  const locationTag = ( (role?.info?.offices) && (role?.info?.offices.length > 0)) ? role?.info?.offices[0].location_name : ''
  const locationCount = ( (role?.info?.offices) && (role?.info?.offices.length > 1)) ? ('+'+(role?.info?.offices.length-1)) : ''

  const talentWaitingCount = role.is_autopilot ? role.metrics?.talent_added_in_last_24_hours : role.metrics?.talent_waiting_count;

  const isFreemium = isFreemiumPlan(data);

  return (
    <div className={cx(classes.contentContainer, { [classes.firstRole]: index === 0 })}>
      {/*Left content container*/}
      <div className={classes.leftContainer}>
        <Stack spacing={0}>
          <Group noWrap>
            <RoleName
              roleId={role.id}
              name={name}
              waitingForSetup={show_info}
              cardRequired={(data?.force_collect_card && data?.stripe_id === null) || false}
              handleSetupComplete={handleSetupComplete}
              handleNavigateToRole={handleNavigateToRole}
            />
            {/* {!role?.completed && ( */}
            <MediaQuery smallerThan={'md'} styles={{ display: 'none' }}>
              <RoleBadges {...role} id={role.id} show_autopilot_badge = {false} is_autopilot={isAutopilotoEnabled} role={role} />
            </MediaQuery>
            {/* )} */}
            {!!role?.greenhouse_job?.job_id && (
              <IntegrationIcon reqId={role.greenhouse_job.requisition_id} type={'greenhouse'} />
            )}
            {!!role?.lever_job?.job_id && <IntegrationIcon type={'lever'} />}
            {!!role?.sap_job?.job_id && <IntegrationIcon reqId={role.sap_job.requisition_id} type={'sap'} />}
            {!!role?.icims_job?.job_id && <IntegrationIcon reqId={role.icims_job.job_id} type={'icims'} />}
          </Group>
          {/* Hide RoleLocation on smaller devices*/}
          <MediaQuery smallerThan={'md'} styles={{ display: 'none' }}>
            <Group>
                <>
                {locationTag ? (<RoleLocation location={ locationTag + ' ' + locationCount } />) : (<RoleLocation location={role?.info?.office_locations}/>)}
                </>
              {role?.info?.environment === 'full-time-remote' && (
                  <Text color="#838485" size="md" lineClamp={1} sx={{ wordBreak: 'break-all'}}>
                    (Remote)
                  </Text>
              )}
            </Group>
          </MediaQuery>          
          
        </Stack>

        {/*Mobile Version Bottom Content*/}
        <MediaQuery largerThan={'md'} styles={{ display: 'none' }}>
          <div className={classes.fullWidth}>
            <Group position={'apart'} noWrap>
              <Group>
                {locationTag ? (<RoleLocation location={ locationTag + ' ' + locationCount } />) : (<RoleLocation location={role?.info?.office_locations}/>)}
                {role?.info?.environment === 'full-time-remote' && (
                  <Text color="#838485" size="md" lineClamp={1} sx={{ wordBreak: 'break-all'}}>
                    (Remote)
                  </Text>
                )}
              </Group>
              <RoleBadges {...role} 
              minimal talent_to_review={role.metrics?.talent_waiting_count} 
              is_autopilot={isAutopilotoEnabled} 
              show_autopilot_badge={true} 
              role={role}
              />
            </Group>
          </div>
        </MediaQuery>
      </div>

      {/*Right content container*/}
      <Group className={classes.rightContainer}>
         { 
         //on sourcing only roles, we don't show the autopilot button
          !role.sourcing_only && (
          <Button 
            variant="subtle"
            onClick={handleAutopilotClick}
            sx={(theme) => ({

              color: isAutopilotoEnabled ?  '#7039ED' : '#838485',
              '&:hover': {
                
                color: isAutopilotoEnabled ? '#7039ED' : '#838485',
              },
              transition: 'color 0.2s ease',
            })}
          >
            {isAutopilotoEnabled ? "Autopilot On" : "Autopilot Off"}
          </Button>
          )}
        <RoleInfoIcon
          className={cx({ [classes.newTalent]: index === 0 })}

          name={'New Talent'}
          hasData={role.metrics?.talent_waiting_count > 0 || role.metrics?.talent_added_in_last_24_hours > 0}
          amount={talentWaitingCount}
          type={'talent-count'}
        >
          <FontAwesomeIcon
            icon={faUser}
            color={role.metrics?.talent_waiting_count > 0 || role.metrics?.talent_added_in_last_24_hours > 0 ? '#838485' : '#DFE1E1'}
            className={classes.userIcon}
          />
        </RoleInfoIcon>
        {role.sourcing_only ? (
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <RoleInfoIcon
              className={cx({ [classes.interviews]: index === 0 })}
              name={'Sourcing Only role'}
              hasData={false}
            >
              <FontAwesomeIcon
                icon={faCalendarXmark}
                color={role.metrics?.talent_interview_count > 0 ? '#838485' : '#b3b3b3'}
                className={classes.calendarIcon}
              />
            </RoleInfoIcon>
          </div>
        ) : (
          <RoleInfoIcon
            className={cx({ [classes.interviews]: index === 0 })}
            name={'Confirmed Interviews'}
            hasData={role.metrics?.talent_interview_count > 0}
            amount={role.metrics?.talent_interview_count}
          >
            <FontAwesomeIcon
              icon={faCalendarCheck}
              width={24}
              height={24}
              color={role.metrics?.talent_interview_count > 0 ? '#838485' : '#DFE1E1'}
              className={classes.calendarIcon}
            />
          </RoleInfoIcon>
        )}

        <Group noWrap spacing={10} sx={{ minWidth: sortType === 'last-reviewed' ? 245 : 'unset' }}>
          <Group
            noWrap
            sx={{ flex: 1, position: 'relative' }}
            mr={20}
            className={cx({ [classes.createdAt]: index === 0 })}
          >
            <Tooltip
              label={<span>Created On</span>}
              styles={{
                body: {
                  fontSize: 12,
                  fontFamily: 'Helvetica',
                },
              }}
              position={'top'}
              placement={'center'}
            >
              <RoleDate date={role.created_at} />
            </Tooltip>
          </Group>
          {sortType === 'last-reviewed' && (
            <Group
              noWrap
              sx={{ flex: 1, position: 'relative', minHeight: 24.8 }}
              mr={20}
              className={cx({ [classes.lastReviewed]: index === 0 })}
            >
              <Tooltip
                label={<span>Last Reviewed</span>}
                styles={{
                  body: {
                    fontSize: 12,
                    fontFamily: 'Helvetica',
                  },
                }}
                position={'top'}
                placement={'center'}
              >
                {role.talent_reviewed_date ? <RoleDate date={role.talent_reviewed_date} /> : null}
              </Tooltip>
            </Group>
          )}
        </Group>
        <Menu
          opened={opened}
          onOpen={() => data?.is_active ? handlers.open() : null}
          onClose={handlers.close}
          size={'md'}
          mx={20}
          styles={{
            root: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              button: {
                ':hover': {
                  transition: 'background-color 0.2s ease-in-out',
                  backgroundColor: '#EEEEEE',
                },
                ':focus': {
                  backgroundColor: '#EEEEEE',
                },
              },
            },
          }}
          control={
            <UnstyledButton
              style={{
                borderRadius: 40,
                padding: 4,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Dots color={opened ? '#351B77' : '#B3B3B3'} size={20} />
            </UnstyledButton>
          }
        >
          {!role?.show_info && (
            <div>
              <Menu.Item onClick={handleRoleInfo} style={{ alignItems: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
                <Group>
                  <FontAwesomeIcon icon={faInfoCircle} className={classes.menuIcon}/>
                  Role Info
                </Group>
              </Menu.Item>
              {!isFreemium && (
                <Menu.Item onClick={handleRoleUsers} style={{ alignItems: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
                  <Group>
                    <FontAwesomeIcon icon={faUserPlus} className={classes.roleMembersIcon}/>
                    Role Members
                  </Group>
                </Menu.Item>
              )}

            </div>
          )}
          {!role?.show_info && <Divider size="xs" />}
          {(data?.greenhouse || data?.lever || data?.sap || data?.icims) && (
            <Menu.Item onClick={() => handleLinkToATS(role.id, role.name)} style={{ alignItems: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
              <Group>
                <FontAwesomeIcon icon={faLink} className={classes.menuIcon} />
                Link role to ATS
              </Group>
            </Menu.Item>
          )}
          {!role?.show_info && (
            <Menu.Item onClick={handleExportTalentClicked} style={{ alignItems: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
              <Group>
                <FontAwesomeIcon icon={faArrowDownToLine} className={classes.menuIcon} />
                Export
              </Group>
            </Menu.Item>
          )}
          {!role.completed && !role.show_info && <Divider size="xs" />}
          {role.on_hold && (
            <Menu.Item onClick={handleResumeRole} style={{ alignItems: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
              <Group>
                <FontAwesomeIcon icon={faCirclePlay} className={classes.menuIcon}/>
                Resume Role
              </Group>
            </Menu.Item>
          )}
          {!role.on_hold && !role.completed && (
            <Menu.Item onClick={handlePauseRole} style={{ alignItems: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
              <Group>
                <FontAwesomeIcon icon={faCirclePause} className={classes.menuIcon}/>
                Pause Role
              </Group>
            </Menu.Item>
          )}

          {role.completed && !role?.role_requests?.find((x) => x.type === 'open') && (
            <Menu.Item onClick={handleOpenRole} style={{ alignItems: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
              <Group>
                <FontAwesomeIcon icon={faCirclePlay} className={classes.menuIcon}/>
                Reactivate Role
              </Group>
            </Menu.Item>
          )}
          {!role.completed && (
            <Menu.Item onClick={handleCloseRole} style={{ alignItems: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
              <Group>
                <FontAwesomeIcon icon={faXmark} className={classes.menuIcon}/>
                Close Role
              </Group>
            </Menu.Item>
          )}
        </Menu>
      </Group>
    </div>
  );
};

export default React.memo(RoleRow);
